html
 {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  min-height: 100%;
  width: 100%;
  background: #070707;
  border-radius: 0px 0px 0px 0px;

 
}
html ,body {

  @media screen and (max-width: 769px) {
      /* STYLES HERE */
        width: 600px;
    }
 
}



a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}